export const config = {
  endpoint:
    process.env.REACT_APP_ENV === 'development'
      ? 'https://bff-bxwvvgzpyq-an.a.run.app'
      : 'http://localhost:8080',
  axiosRequestConfig: {
    params: {
      api_key: 'AIzaSyB4hwfxYFGk_SMMizEUzixmtof2uGFtVFY'
    }
  },
  microCmsRequestConfig: {
    headers: {
      'x-api-key': '1261e9e0-931e-423e-9d4a-d723f8c3438e'
    }
  },
  breadOptions: [
    { value: 0, text: 'ルーのみ(-¥200)' },
    { value: 1, text: 'プレーンナン' },
    { value: 2, text: 'ゴマナン(+¥200)' },
    { value: 3, text: 'ガーリックナン(+¥200)' },
    { value: 4, text: 'チーズナン(+¥300)' },
    { value: 5, text: 'サフランライス' }
  ],
  meatOptions: [
    { value: 1, text: 'チキン' },
    { value: 2, text: 'ポーク(+¥100)' },
    { value: 3, text: 'マトン(+¥200)' }
  ]
}
