import React, { useContext } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { StoreContext } from '../../context/StoreContext'
import { auth } from '../../lib/firebase'

export const Header = () => {
  const {
    store: { user }
  } = useContext(StoreContext)

  const history = useHistory()

  const handleSignOut = () => {
    auth.signOut().then(() => {
      window.location.href = '/login'
    })
  }

  return (
    <>
      <Container>
        <StoreName>{user && user.email!.replace('@ganesh.shop', '')}</StoreName>
        <SignOut onClick={handleSignOut}>ログアウト</SignOut>
      </Container>
    </>
  )
}

const Container = styled.div`
  display: block;
  height: 60px;
  background-color: #fff;
  border-bottom: 1px #f0f0f0 solid;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 0 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
`

const StoreName = styled.div`
  color: #000;
  font-size: 16px;
  text-align: center;
  line-height: 60px;
`

const SignOut = styled.button`
  background: none;
  border: none;
  color: #f44336;
  cursor: pointer;
  line-height: 60px;

  :focus {
    outline: 0;
  }
`
