import React from 'react'
import { Descriptions as AntdDescriptions, Badge } from 'antd'
import { Checkout } from '../../../models/checkout'
import { datetime } from '../../../utils'

export interface Props {
  checkout: Checkout
}

export const Descriptions = (props: Props) => {
  const { checkout } = props

  return (
    <AntdDescriptions bordered>
      <AntdDescriptions.Item label="注文No.">
        {checkout.id}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label="受け取り日時">
        {`${datetime(checkout.receiveAt)}`}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label="注文日時">
        {`${datetime(checkout.placedAt)}`}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label="お支払い">
        {checkout.paymentMethod === 1 ? '現金' : 'カード'}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label="合計金額">
        {`${(checkout.price + checkout.tax).toLocaleString()}円`}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label="ステータス">
        {checkout.status === 2 ? (
          <Badge status="warning" text="Pending" />
        ) : checkout.status === 3 ? (
          <Badge status="processing" text="Processing" />
        ) : checkout.status === 4 ? (
          <Badge status="success" text="Completed" />
        ) : (
          <></>
        )}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label="お名前">
        {checkout.name}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label="電話番号">
        {checkout.tel}
      </AntdDescriptions.Item>

      {/* <AntdDescriptions.Item label="税抜金額">
        {`${checkout.price.toLocaleString()}円`}
      </AntdDescriptions.Item>
      <AntdDescriptions.Item label="消費税">{`${checkout.tax.toLocaleString()}円`}</AntdDescriptions.Item> */}
    </AntdDescriptions>
  )
}
