import React, { ReactNode, useState, useEffect } from 'react'
import * as api from '../lib/httpService/api'
import { auth, db } from '../lib/firebase'

import { StoreContext, Store } from '../context/StoreContext'

export const ContextProvider = ({ children }: { children: ReactNode }) => {
  const initialStoreState: Store = {
    user: null,
    shopId: '',
    pendingCheckouts: [],
    processingCheckouts: [],
    completedCheckouts: [],
    fetchingUser: true,
    fetchingCheckouts: true,
    fetchingCompletedCheckouts: true
  }

  const [store, updateStore] = useState(initialStoreState)

  const fetchCheckouts = async () => {
    if (store.shopId) {
      updateStore(prevState => {
        return { ...prevState, fetchingCheckouts: true }
      })

      const checkouts = await api.getCheckoutList({
        shopId: store.shopId,
        status: '2,3',
        sortBy: 'receive_at',
        sortDirection: 'asc'
      })

      updateStore(prevState => {
        return {
          ...prevState,
          pendingCheckouts:
            checkouts && checkouts.filter(checkout => checkout.status === 2),
          processingCheckouts:
            checkouts && checkouts.filter(checkout => checkout.status === 3),
          fetchingCheckouts: false
        }
      })
    }
  }

  const fetchCompletedCheckouts = async () => {
    if (store.shopId) {
      const completedCheckouts = await api.getCheckoutList({
        shopId: store.shopId,
        status: '4',
        sortBy: 'created_at',
        sortDirection: 'desc',
        limit: 10
      })

      updateStore(prevState => {
        return { ...prevState, completedCheckouts }
      })
    }
  }

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      console.log(user)
      if (user) {
        user.getIdTokenResult().then(idTokenResult => {
          if (idTokenResult.claims.admin) {
            updateStore(prevState => {
              return {
                ...prevState,
                user,
                shopId: idTokenResult.claims.shopId,
                fetchingUser: false
              }
            })
          }
        })
      } else {
        updateStore(prevState => {
          return {
            ...prevState,
            user: null,
            shopId: '',
            fetchingUser: false
          }
        })
      }
    })
  }, [])

  useEffect(() => {
    db.collection('checkoutLogs')
      .where('status', '>=', 2)
      .where('status', '<=', 3)
      .onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
          fetchCheckouts()
          fetchCompletedCheckouts()
        })
      })
  }, [store.shopId])

  return (
    <StoreContext.Provider
      value={{
        store,
        signIn: async (email: string, password: string) => {
          const uc = await auth.signInWithEmailAndPassword(email, password)
          if (uc) {
            updateStore(prevState => {
              return { ...prevState, user: uc.user }
            })
          }
        },
        fetchCheckouts,
        fetchCompletedCheckouts,
        updateCheckoutStatus: async (checkoutId, updateCheckoutStatusDto) => {
          api.updateCheckoutStatus(checkoutId, updateCheckoutStatusDto)
        }
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
