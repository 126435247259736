import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { Form, Input, Button, Checkbox } from 'antd'
import { StoreContext } from '../../context/StoreContext'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 }
}
const tailLayout = {
  wrapperCol: { offset: 8, span: 8 }
}

export const Login = () => {
  const {
    store: { user },
    signIn
  } = useContext(StoreContext)

  const onFinish = (values: any) => {
    signIn(values.email, values.password)
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return user ? (
    <Redirect to="/" />
  ) : (
    <FormContainer>
      <Title>Ganeshオンラインオーダー 注文管理</Title>
      <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'メールアドレスを入力してください' }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'パスワードを入力してください' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item {...tailLayout} name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </FormContainer>
  )
}

const FormContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  width: 100%;
`

const Title = styled.div`
  color: #000;
  font-size: 16px;
  margin-bottom: 40px;
  text-align: center;
`
