import { request } from '..'
import { UpdateCheckoutStatusDto } from '../../../dto/update-checkout-status.dto'

export const updateCheckoutStatus = async (
  checkoutId: number,
  updateCheckoutStatusDto: UpdateCheckoutStatusDto
) => {
  return await request(
    {
      url: `/orders/checkouts/${checkoutId}/update_status`,
      method: 'PATCH',
      data: updateCheckoutStatusDto
    },
    true
  ).catch(err => {
    throw err
  })
}
