import React from 'react'
import styled from 'styled-components'
import { Spin } from 'antd'

export const MyCircularProgress = () => {
  return (
    <>
      <SpinWrapper>
        <Spin />
      </SpinWrapper>
    </>
  )
}

const SpinWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
`
