import React from 'react'
import firebase from 'firebase'
import { Checkout } from '../models/checkout'
import { UpdateCheckoutStatusDto } from '../dto/update-checkout-status.dto'

export interface Store {
  user: firebase.User | null
  shopId: string
  pendingCheckouts: Checkout[]
  processingCheckouts: Checkout[]
  completedCheckouts: Checkout[]
  fetchingUser: boolean
  fetchingCheckouts: boolean
  fetchingCompletedCheckouts: boolean
}

export interface Context {
  store: Store
  signIn: (email: string, password: string) => any
  fetchCheckouts: () => any
  fetchCompletedCheckouts: () => any
  updateCheckoutStatus: (
    checkoutId: number,
    updateCheckoutStatusDto: UpdateCheckoutStatusDto
  ) => any
}

export const StoreContext = React.createContext({} as Context)
