import React, { ReactNode, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { StoreContext } from '../../context/StoreContext'
import { MyCircularProgress } from '../common/MyCircularProgress'

export interface Props {
  children: ReactNode
}

export const Auth = (props: Props) => {
  const { children } = props

  const {
    store: { user, fetchingUser }
  } = useContext(StoreContext)

  return fetchingUser ? (
    <MyCircularProgress />
  ) : user ? (
    <>
      <Container>{children}</Container>
    </>
  ) : (
    <Redirect to="/login" />
  )
}

const Container = styled.div`
  padding-top: 60px;
`
