import React, { useContext, useState, useEffect } from 'react'
import { Tabs, Card, Button, Empty } from 'antd'
import styled from 'styled-components'
import { StoreContext } from '../../context/StoreContext'
import { Statistic } from './Statistic'
import { ItemTable } from './ItemTable'
import { Descriptions } from './Descriptions'
import { MyCircularProgress } from '../common/MyCircularProgress'

const { TabPane } = Tabs

export const CheckoutList = () => {
  const {
    store: {
      pendingCheckouts,
      processingCheckouts,
      completedCheckouts,
      fetchingCheckouts,
      fetchingCompletedCheckouts
    },
    updateCheckoutStatus
  } = useContext(StoreContext)

  const [playing, setPlaying] = useState(false)

  useEffect(() => {
    pendingCheckouts.length > 0 ? setPlaying(true) : setPlaying(false)
  }, [pendingCheckouts])

  const handleConfirm = (checkoutId: number) => {
    updateCheckoutStatus(checkoutId, { status: 3 })
  }

  const handleComplete = (checkoutId: number) => {
    updateCheckoutStatus(checkoutId, { status: 4 })
  }

  return fetchingCheckouts && fetchingCompletedCheckouts ? (
    <MyCircularProgress />
  ) : (
    <>
      {playing && (
        <iframe
          src="https://storage.googleapis.com/static.ganesh.shop/notify/index.html"
          allow="autoplay"
          id="audio"
          title="notify"
          style={{ display: 'none' }}
        />
      )}

      <Container>
        <Statistic
          pending={pendingCheckouts.length}
          processing={processingCheckouts.length}
        />
        <Tabs defaultActiveKey="1">
          <TabPane tab="未完了" key="1">
            {pendingCheckouts.length > 0 &&
              pendingCheckouts.map((checkout, index) => (
                <CardWrapper key={index}>
                  <Card>
                    <ItemTable items={checkout.items} />
                    <br />
                    <Descriptions checkout={checkout} />
                    <ButtonWrapper>
                      <Button
                        type="default"
                        block
                        onClick={() => handleConfirm(checkout.id)}
                      >
                        確認
                      </Button>
                    </ButtonWrapper>
                  </Card>
                </CardWrapper>
              ))}
            {processingCheckouts.length > 0 &&
              processingCheckouts.map((checkout, index) => (
                <CardWrapper key={index}>
                  <Card>
                    <ItemTable items={checkout.items} />
                    <br />
                    <Descriptions checkout={checkout} />
                    <ButtonWrapper>
                      <Button
                        type="primary"
                        block
                        onClick={() => handleComplete(checkout.id)}
                      >
                        完了
                      </Button>
                    </ButtonWrapper>
                  </Card>
                </CardWrapper>
              ))}
            {pendingCheckouts.length === 0 &&
              processingCheckouts.length === 0 && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
          </TabPane>
          <TabPane tab="完了済み" key="2">
            {completedCheckouts.map((checkout, index) => (
              <CardWrapper key={index}>
                <Card>
                  <ItemTable items={checkout.items} />
                  <Descriptions checkout={checkout} />
                </Card>
              </CardWrapper>
            ))}
          </TabPane>
        </Tabs>
      </Container>
    </>
  )
}

const Container = styled.div`
  padding: 80px 24px 24px;
`

const CardWrapper = styled.div`
  margin-bottom: 16px;
`

const ButtonWrapper = styled.div`
  margin: 24px auto 0;
  width: 200px;
`
