import React from 'react'
import { Statistic as AntdStatistic, Card, Row, Col } from 'antd'

export interface Props {
  pending: number
  processing: number
}

export const Statistic = (props: Props) => {
  const { pending, processing } = props

  return (
    <Row gutter={16}>
      <Col span={12}>
        <Card>
          <AntdStatistic
            title="未確認"
            value={pending}
            valueStyle={{ color: '#f44336' }}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card>
          <AntdStatistic title="処理中" value={processing} />
        </Card>
      </Col>
    </Row>
  )
}
