import { request } from '..'
import { config } from '../../../config'
import { Checkout } from '../../../models/checkout'

export interface Params {
  shopId?: string
  status?: string
  limit?: number
  offset?: number
  sortBy?: string
  sortDirection?: string
}

export const getCheckoutList = async (params: Params): Promise<Checkout[]> => {
  return await request(
    {
      url: '/orders/checkouts',
      method: 'GET',
      headers: config.microCmsRequestConfig.headers,
      params
    },
    true
  ).catch(err => {
    throw err
  })
}
