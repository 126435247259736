import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import './App.css'
import { Auth } from './components/Auth'
import { Header } from './components/Header'
import { Login } from './components/Login'
import { CheckoutList } from './components/CheckoutList'

function App() {
  return (
    <Router>
      <Header />
      <Auth>
        <Route exact path="/" component={CheckoutList} />
      </Auth>
      <Route path="/login" component={Login} />
    </Router>
  )
}

export default App
