import React from 'react'
import { Descriptions, Table } from 'antd'
import _map from 'lodash/map'
import _isEmpty from 'lodash/isEmpty'
import { Item } from '../../../models/item'

export interface Props {
  items: Item[]
}

const itemColumns = [
  {
    title: '商品名',
    dataIndex: ['product', 'name'],
    key: 'product'
  },
  {
    title: '',
    dataIndex: 'displayCustomizations',
    key: 'displayCustomizations'
  },
  {
    title: '備考',
    dataIndex: 'specialInstruction',
    key: 'specialInstruction'
  },
  {
    title: '個数',
    dataIndex: 'quantity',
    key: 'quantity'
  },
  {
    title: '値段',
    dataIndex: 'displayPrice',
    key: 'displayPrice'
  }
]

export const ItemTable = (props: Props) => {
  const { items } = props

  items.forEach(item => {
    if (!_isEmpty(item.customizations)) {
      item.displayPrice = `${item.price.toLocaleString()}円`
      item.displayCustomizations = (
        <Descriptions bordered size="small" key={item.id}>
          {_map(item.customizations, (value, key) => (
            <Descriptions.Item label={key} key={key}>
              {value}
            </Descriptions.Item>
          ))}
        </Descriptions>
      )
    }
  })

  return (
    <Table
      dataSource={items}
      columns={itemColumns}
      size="small"
      rowKey="id"
      pagination={false}
    />
  )
}
